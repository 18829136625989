<template>
  <div>
    <a-row class="mb-10 header" style="padding-bottom: 0">
      <a-col :span="12">
        <h2 class="upper mt-10">VOUCHER ID {{ voucher.id }}</h2>
        <div class="txt name">
          {{ voucher.client_name }}
        </div>
      </a-col>

      <a-col class="cards" :span="12">
        <a-row :gutter="20">
          <a-col :span="8">
            <div class="box total">
              <div class="name">TOTAL</div>
              <div class="number">
                {{ formatPriceCurrency(voucher.raw.value, voucher.currency) }}
              </div>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="box available">
              <div class="name">DISPONÍVEL</div>
              <div class="number">
                {{
                  formatPriceCurrency(
                    voucher.raw.available_value,
                    voucher.currency
                  )
                }}
              </div>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="box used">
              <div class="name">UTILIZADO</div>
              <div class="number">
                {{
                  formatPriceCurrency(voucher.raw.spent_value, voucher.currency)
                }}
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>

    <a-tabs
      type="card"
      class="miles-cards-tabs"
      :defaultActiveKey="activeVouchersTab"
      @change="onChangeTabs"
    >
      <a-tab-pane key="voucher" tab="Dados do Voucher" force-render>
        <div class="travel-drawer">
          <EditVoucherData
            @getUpdatedVoucher="getVoucher()"
            :voucher="voucher"
            type="update"
            :fieldSizes="{
              user_id: 6,
              issuer_id: 6,
              customer_id: 6,
              airline_id: 6,
              create_customer: 2,
              voyage_id: 6,
              voyage_value: 6,
              voucher_code: 8,
              currency: 3,
              value: 6,
              expires_at: 7,
              client_name: 6,
              identifier: 6,
              receipt_date: 6,
              status: 6,
            }"
          />
        </div>
      </a-tab-pane>

      <a-tab-pane key="extract" force-render>
        <template slot="tab">
          Extrato
          <a-tag class="ml-5" color="blue">
            {{
              voucherTransactions.pagination.total != 0
                ? voucherTransactions.pagination.total
                : 0
            }}
          </a-tag>
        </template>

        <a-row class="mb-10" type="flex" justify="end">
          <a-col>
            <a-button
              size="small"
              @click="openManualEmission = true"
              style="font-size: 13px"
            >
              <a-icon type="logout" />
              Retirada manual
            </a-button></a-col
          >
        </a-row>

        <a-modal
          width="380px"
          :visible="openManualEmission"
          :footer="false"
          @cancel="openManualEmission = false"
        >
          <template slot="title">
            <a-icon class="mr-5 red" type="logout" /> RETIRADA MANUAL
          </template>

          <div v-if="openManualEmission">
            <div class="mt-10 relative">
              <a-form-item class="travel-input-outer">
                <label class="filled"
                  >Valor a ser retirado ({{
                    formatCurrency(voucher.currency ? voucher.currency : "BRL")
                  }})</label
                >
                <a-input
                  class="travel-input"
                  :placeholder="
                    'Valor a ser retirado (' +
                    formatCurrency(
                      voucher.currency ? voucher.currency : 'BRL'
                    ) +
                    ')'
                  "
                  v-currency="{
                    currency: {
                      prefix: '',
                      suffix: '',
                    },
                    distractionFree: false,
                    valueAsInteger: false,
                    precision: 2,
                    autoDecimalMode: true,
                    allowNegative: false,
                  }"
                  v-model="voucherEmission.value"
                >
                  <template slot="prefix">
                    {{
                      formatCurrency(
                        voucher.currency ? voucher.currency : "BRL"
                      )
                    }}
                  </template>
                </a-input>
              </a-form-item>
            </div>

            <div class="mt-10 relative">
              <a-form-item class="travel-input-outer">
                <label class="filled">ID do Voyage</label>
                <a-input
                  class="travel-input"
                  :placeholder="'ID do Voyage'"
                  v-model="voucherEmission.voyageId"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </div>

            <div class="mt-30 a-center">
              <a-popconfirm
                :title="
                  'Tem certeza que deseja retirar ' +
                  formatCurrency(voucher.currency) +
                  ' ' +
                  voucherEmission.value +
                  '?'
                "
                ok-text="Sim"
                cancel-text="Não"
                :disabled="voucherEmission.value != undefined ? false : true"
                @confirm="confirmVoucherEmission"
              >
                <a-button
                  :loading="voucherEmission.loading"
                  :disabled="voucherEmission.value != undefined ? false : true"
                  type="primary"
                >
                  CONFIRMAR RETIRADA
                </a-button>
              </a-popconfirm>
            </div>

            <!-- <pre>
                        {{ voucherEmission }}
                        </pre> -->
          </div>
        </a-modal>

        <a-row
          class="travel-filters mt-0 mb-0 pt-0 pb-0 bt-0 bb-0"
          :gutter="20"
        >
          <a-col :span="4">
            <div class="travel-input">
              <label class="filled">ID Operação</label>
              <a-input
                placeholder=""
                v-model="voucherTransactions.filters.operationId"
                @change="getVoucherTransactions()"
              />
            </div>
          </a-col>
          <a-col :span="4">
            <div class="travel-input">
              <label class="filled">ID contrato</label>
              <a-input
                placeholder=""
                v-model="voucherTransactions.filters.contractId"
                @change="getVoucherTransactions()"
              />
            </div>
          </a-col>

          <a-col :span="8">
            <div class="travel-input">
              <label class="filled">Operação</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                mode="multiple"
                v-model="voucherTransactions.filters.operation.selected"
                show-search
                style="width: 100%"
                @change="getVoucherTransactions()"
              >
                <a-select-option
                  v-for="(item, index) of voucherTransactions.filters.operation
                    .list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>
          <a-col v-if="$root.isAdmin()" :span="8">
            <div class="travel-input">
              <label class="filled">Usuários</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                mode="multiple"
                v-model="voucherTransactions.filters.users.selected"
                optionFilterProp="txt"
                show-search
                style="width: 100%"
                @change="getVoucherTransactions()"
              >
                <a-select-option
                  v-for="(item, index) of voucherTransactions.filters.users
                    .list"
                  :key="index"
                  :value="item.id"
                  :txt="item.first_name"
                >
                  {{ item.first_name }} {{ item.last_name }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>
        </a-row>
        <div class="travel-table last-itens">
          <HayaTable
            :data-source="voucherTransactions.list"
            :loading="voucherTransactions.loading"
            :pagination="false"
            :paginator="voucherTransactions.pagination"
            @getList="
              getVoucherTransactions(voucherTransactions.pagination.page)
            "
            :columns="[
              {
                title: 'ID Operação',
                dataIndex: 'voucher_transaction_id',
                key: 'voucher_transaction_id',
                class: 'voucher_transaction_id',
              },
              {
                title: 'ID Voyage',
                dataIndex: 'voyage_id',
                key: 'voyage_id',
              },
              {
                title: `Valor (${this.formatCurrency(this.voucher.currency)})`,
                scopedSlots: {
                  customRender: 'value',
                },
              },
              {
                title: 'Operação',
                scopedSlots: {
                  customRender: 'operation',
                },
              },
              {
                title: 'ID Contrato',
                scopedSlots: {
                  customRender: 'contract_id',
                },
              },
              {
                title: 'Usuário',
                dataIndex: 'user',
                key: 'user',
                scopedSlots: {
                  customRender: 'user',
                },
              },
              {
                title: 'Data/Hora',
                scopedSlots: {
                  customRender: 'created',
                },
              },
              {
                title: '',
                align: 'right',
                fixed: 'right',
                scopedSlots: { customRender: 'action' },
              },
            ]"
          >
            <div slot="total_miles" slot-scope="record">
              <div :class="record.is_canceled == '1' ? 'line-through' : ''">
                {{ formatMilesOperation(record) }}
              </div>
            </div>

            <div slot="user" slot-scope="record">
              {{ record.first_name }}
              {{ record.last_name }}
            </div>

            <div slot="value" slot-scope="record">
              <div :class="record.is_canceled == '1' ? 'line-through' : ''">
                {{ record.value }}
              </div>
            </div>

            <div slot="operation" slot-scope="record">
              <span v-html="formatOperation(record.operation)" />
              <a-tooltip v-if="record.extra_info">
                <template slot="title">
                  {{ record.extra_info }}
                </template>
                <a-icon
                  class="ml-5 relative c-pointer"
                  style="font-size: 12px; color: #aaa; top: 2px"
                  type="info-circle"
                />
              </a-tooltip>
            </div>

            <div slot="contract_id" slot-scope="record">
              <div v-if="record.contract_id > 0">
                <a
                  :href="`/contracts/edit/${record.contract_id}`"
                  target="_blank"
                >
                  {{ record.contract_id }}
                </a>
              </div>
            </div>

            <template slot="created" slot-scope="record">
              {{ record.created }}
            </template>

            <div class="table-actions" slot="action" slot-scope="record">
              <a-dropdown
                overlayClassName="actions-dropdown"
                v-if="
                  ($root.isAdmin() && record.operation == 'add') ||
                  record.operation == 'new' ||
                  record.operation == 'subtract'
                "
                :trigger="['click']"
                placement="bottomRight"
              >
                <a style="font-size: 12px; color: #aaa">
                  <i class="fas fa-ellipsis-h"></i>
                </a>

                <a-menu slot="overlay">
                  <a-menu-item>
                    <a-popconfirm
                      v-if="record.operation == 'subtract'"
                      title="Tem certeza que deseja cancelar"
                      ok-text="Sim"
                      cancel-text="Não"
                      @confirm="cancelVoucherTransaction(record)"
                    >
                      <i class="far fa-times-circle mr-5"></i>
                      Cancelar
                    </a-popconfirm>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </HayaTable>
        </div>
      </a-tab-pane>

      <a-tab-pane key="notes" force-render>
        <template slot="tab">
          Anotações
          <a-tag class="ml-5" color="blue" :id="`count-notes-${voucher.id}`">
            0
          </a-tag>
        </template>
        <Notes
          module="miles-card-voucher"
          :noteReasonsCategoryid="19"
          :moduleId="voucher.id"
        />
      </a-tab-pane>

      <a-tab-pane v-if="$root.isAdmin()" key="logs" force-render>
        <template slot="tab">
          LOGS
          <a-tag class="ml-5" color="blue" :id="`count-logs-${voucher.id}`">
            0
          </a-tag>
        </template>

        <div style="height: 420px; overflow: auto">
          <LogsTimelineV2
            class="mt-20"
            module="miles-card-voucher"
            :id="voucher.id"
          />
        </div>
      </a-tab-pane>

      <div slot="tabBarExtraContent">
        <a-tooltip v-if="auditVouchersTransactions()" placement="left">
          <template slot="title">
            Este botão apenas atualizará os valores do voucher
          </template>
          <a-button
            @click="updateVoucherValues"
            class="f12"
            type="primary"
            size="small"
            :loading="loadingUpdateVoucher"
          >
            Atualizar valores
          </a-button>
        </a-tooltip>
      </div>
    </a-tabs>
  </div>
</template>

<script>
import HayaTable from "../../../components/general/HayaTable.vue";
import voucherTransactionsMixins from "@/mixins/vouchers-transactions/voucherTransactionsMixins.js";
import EditVoucherData from "../../vouchers/forms/EditVoucherData.vue";
import formatThings from "@/mixins/general/formatThings";
import Notes from "../../../components/general/Notes.vue";
import LogsTimelineV2 from "../../../components/logs/LogsTimelineV2.vue";

export default {
  props: {
    voucherId: String,
  },
  mixins: [voucherTransactionsMixins, formatThings],
  components: { HayaTable, EditVoucherData, Notes, LogsTimelineV2 },
  data() {
    return {
      voucher: {},
      voucherEmission: {
        loading: false,
        value: undefined,
        voyageId: undefined,
      },
      openManualEmission: false,
      loadingUpdateVoucher: false,
      activeVouchersTab: "voucher",
    };
  },
  mounted() {
    //
    this.getVoucher();

    //
    this.$http
      .get("/user/read")
      .then(({ data }) => {
        this.voucherTransactions.filters.users.list = data;
      })
      .catch(({ response }) => {
        response;
      });
  },
  methods: {
    getVoucher() {
      this.$http
        .post(`/miles-card-voucher/details?id=${this.voucherId}`)
        .then(({ data }) => {
          this.voucher = data;
          this.voucherTransactions.filters.voucherId = this.voucherId;
          this.getVoucherTransactions();
        })
        .catch(({ response }) => {
          response;
        });
    },
    updateVoucherValues() {
      let values = {};
      values.id = this.voucher.id;
      values.field = "spent_value";
      values.value = this.voucher.raw.value;
      values.action = "update-voucher-values";
      values.module = "miles-card-voucher";
      values.description = `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} atualizou os valores do Voucher #${this.voucher.id}.`;

      values.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.loadingUpdateVoucher = true;

      this.$http
        .post("/miles-card-voucher/update-field", values)
        .then(({ data }) => {
          this.getVoucher();
          this.loadingUpdateVoucher = false;
          this.$message.success(data.message);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.loadingUpdateVoucher = false;
        });
    },
    onChangeTabs(tab) {
      this.activeVouchersTab = tab;

      if (tab == "extract") {
        this.getVoucher();
        this.getVoucherTransactions();
      }
    },
    cancelVoucherTransaction(transaction) {
      transaction.raw.is_canceled = 1;
      transaction.raw.status = "canceled";
      transaction.raw.action = "cancel-voucher-emission";
      transaction.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.$http
        .post("/voucher-transactions/update", transaction.raw)
        .then(({ data }) => {
          this.getVoucher();
          this.getVoucherTransactions();
          this.$message.success(data.message);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    confirmVoucherEmission() {
      if (this.voucherEmission.value) {
        console.log(
          parseFloat(this.convertNumberToDatabase(this.voucherEmission.value)),
          " <= ",
          parseFloat(this.voucher.available_value)
        );
        if (
          parseFloat(
            this.convertNumberToDatabase(this.voucherEmission.value)
          ) <= parseFloat(this.voucher.available_value)
        ) {
          this.voucherEmission.loading = true;
          this.$http
            .post("/voucher-transactions/create", {
              contract_id: 0,
              customer_id: 0,
              is_canceled: 0,
              user_id: this.$store.state.userData.id,
              voucher_code: this.voucher.voucher_code,
              currency: this.voucher.currency,
              value: this.convertNumberToDatabase(this.voucherEmission.value),
              operation: "subtract",
              extra_info: "",
              voucher_id: this.voucher.id,
              voyage_id: this.voucherEmission.voyageId,
            })
            .then(({ data }) => {
              this.getVoucher();
              this.openManualEmission = false;
              this.voucherEmission.loading = false;
              this.voucherEmission.value = undefined;
              this.voucherEmission.voyageId = undefined;
              this.$message.success(data.message);
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.voucherEmission.value = undefined;
              this.voucherEmission.voyageId = undefined;
              this.voucherEmission.loading = false;
            });
        } else {
          this.$message.warning(
            "O valor a ser retirado não pode ser maior do que o disponível."
          );
        }
      } else {
        this.$message.warning("Insira o valor a ser retirado.");
      }
    },
    formatOperation(operation) {
      if (operation == "subtract") {
        operation = `<font class="tag return">EMISSÃO</font>`;
      }

      if (operation == "canceled") {
        operation = `<font class="tag subtract">CANCELADO</font>`;
      }

      return operation;
    },
    auditVouchersTransactions() {
      let flag = false,
        spentVal = this.voucher.raw.spent_value,
        totalTransactions = this.voucher.total_transactions;

      if (spentVal == 0.0 && totalTransactions > 0) {
        flag = true;
      }

      return flag;
    },
  },
};
</script>
