import _ from "lodash";
_
export default {
  data() {
    return {
      voucherTransactions: {
        loading: false,
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
        details: {},
        list: [],
        filters: {
          users: {
            list: [],
            selected: [],
          },
          operation: {
            list: [{
              label: "Cancelado",
              value: "canceled",
            }, {
              label: "Emissão",
              value: "subtract",
            }],
            selected: [],
          },
          period: {
            selected: [],
          },
          voucherId: "",
          voucherCode: "",
          operationId: "",
          contractId: "",
          searchTerm: "",
          order: "desc",
          orderBy: "created",
        }
      }
    };
  },
  methods: {
    voucherTransactionsTableChange(pagination, filters, sorter) {
      console.log("sorter s", sorter, pagination, filters);
      this.voucherTransactions.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.voucherTransactions.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getVoucherTransactions();
    },
    changePage(current) {
      this.voucherTransactions.pagination.page = current;
      this.getVoucherTransactions();
    },
    changePageSize(current, pageSize) {
      this.voucherTransactions.pagination.page = current;
      this.voucherTransactions.pagination.perPage = pageSize;
      this.getVoucherTransactions();
    },
    getVoucherTransactions(samePage) {

      let filters = "";

      this.voucherTransactions.loading = true;

      if (this.voucherTransactions.filters.searchTerm) {
        filters += `&s=${this.voucherTransactions.filters.searchTerm}`;
      }

      if (this.voucherTransactions.filters.contractId) {
        filters += `&contract_id=${this.voucherTransactions.filters.contractId}`;
      }

      if (this.voucherTransactions.filters.voucherId) {
        filters += `&voucher_id=${this.voucherTransactions.filters.voucherId}`;
      }

      if (this.voucherTransactions.filters.voucherCode) {
        filters += `&voucher_code=${this.voucherTransactions.filters.voucherCode}`;
      }

      if (this.voucherTransactions.filters.operationId) {
        filters += `&voucher_transaction_id=${this.voucherTransactions.filters.operationId}`;
      }

      if (this.voucherTransactions.filters.operation.selected.length > 0) {
        filters += `&operation=${this.voucherTransactions.filters.operation.selected}`;
      }

      if (this.voucherTransactions.filters.users.selected.length > 0) {
        filters += `&user_id=${this.voucherTransactions.filters.users.selected}`;
      }

      if (this.voucherTransactions.filters.period.selected.length > 0) {
        filters += `&period=${this.voucherTransactions.filters.period.selected[0]}|${this.voucherTransactions.filters.period.selected[1]}`;
      }

      filters += `&order=${this.voucherTransactions.filters.order}&order-by=${this.voucherTransactions.filters.orderBy}`;

      this.$http
        .get(
          `/voucher-transactions/list?page=${this.voucherTransactions.pagination.page}&per_page=${this.voucherTransactions.pagination.perPage}${filters}`
        )
        .then(({
          data
        }) => {
          if (!samePage) {
            this.voucherTransactions.pagination.page = 1;
          }
          this.voucherTransactions.pagination.total = data.meta.total;
          this.voucherTransactions.pagination.totalPages = data.meta.total_pages;
          this.voucherTransactions.list = data.data;
          this.voucherTransactions.loading = false;

          this.excelFile.data = data.data;
          this.excelFile.footer = [];
          this.excelFile.footer.push(" ");
        })
        .catch(({
          response
        }) => {
          this.voucherTransactions.loading = false;
          this.voucherTransactions.list = response.data.data;
          this.pagination.total = response.data.meta.total;
          this.pagination.totalPages = response.data.meta.total_pages;

          this.excelFile.data = [];
          this.excelFile.footer = [];
          this.excelFile.footer.push(" ");
        });
    },

  }

}